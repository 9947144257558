.custom-input-container {
  max-width: 120px;
   display: flex !important;
   align-items: center !important;
   justify-content: flex-start !important;
   border: 1px solid #b7bfc6;
   border-radius: 5px;
   height: 30px;
   gap: 4px;
   padding: 0 5px;
}

.cash-custom-input {
  width: 80px;
  border: none;
  height: 28px !important;

  &:focus {
    outline: none;
  }
}