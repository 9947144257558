.saleSummary_container{
    width: 100%;
    margin: 10px 0;
    .saleSummary__header{
        font-size: 1rem;
        font-weight: normal;
    }
    .saleSummary__body{
        transition: all .3s ease-in-out;
    }
    .saleSummary__header_title_container{
        display: flex;
        justify-content: space-between;
    }
    .saleSummary__primary-title{
        font-size: 20px;
        font-weight: 600;
        color: #313C52;
    }

    .saleSummary__header_title_amount{
        font-size: 20px;
        font-weight: 500;
        color: #64707A;
    }
    .saleSummary__header_title_amount_second{
        font-size: 15px;
        font-weight: 500;
        display: flex;
        justify-content: flex-end;
        color: #64707A;
    }

    .text-left>span{
        font-size: 14px;
        font-weight: 600;
        color: #313c52;
    }
    .text-right>span{
        font-size: 12px;
        font-weight: 600;
        color: #313c52;
        margin-right:4px ;
    }
    .saleSummary_left{
        color: #64707A;
        font-size: 15px;
        font-weight: 600;
    }
    .saleSummary_total{
        color: #313c52;
        font-size: 15px;
        font-weight: 600;
    }

}