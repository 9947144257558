.size-120 {
    @extend %size-120;
  }
  
  .size-100 {
    @extend %size-100;
  }
  
  .size-90 {
    @extend %size-90;
  }
  
  .size-80 {
    @extend %size-80;
  }
  
  .size-70 {
    @extend %size-70;
  }
  
  .size-60 {
    @extend %size-60;
  }
  
  .size-50 {
    @extend %size-50;
  }
  
  .size-40 {
    @extend %size-40;
  }
  
  .size-30 {
    @extend %size-30;
  }
  
  .size-30 {
    @extend %size-30;
  }
  
  .size-20 {
    @extend %size-20;
  }
  
  .size-10 {
    @extend %size-10;
  }
  
  .size-8 {
    @extend %size-8;
  }


  .font-size-8 {
    font-size: 8px !important;
  }
  .font-size-10 {
    font-size: 10px !important;
  }
  .font-size-12 {
    font-size: 12px !important;
  }
  .font-size-14 {
    font-size: 14px !important;
  }
  .font-size-16 {
    font-size: 16px !important;
  }
  .font-size-18 {
    font-size: 18px !important;
  }
  .font-size-20 {
    font-size: 20px !important;
  }
  .font-size-22 {
    font-size: 22px !important;
  }
  .font-size-24 {
    font-size: 24px !important;
  }
  .font-weight-bold {
    font-weight: 700 !important;
}
.font-white {
  color: white !important;
}
.font-weight-500 {
  font-weight: 500 !important;
}
.font-weight-700 {
  font-weight: 700 !important;
}

.text-ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}


.f-1 {
  flex: 1
}
.f-2 {
  flex: 2
}
.f-3 {
  flex: 3
}
