.root {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    cursor: pointer;
    padding-left: 0 !important;
    font-family: 'Roboto';
  };
  .rootSquare {
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }
  .wrap {
    display: flex;
    position: relative;
    overflow: hidden;
    font-size: 1.25rem;
    align-items: center;
    flex-shrink: 0;
    user-select: none;
    border-radius: 50%;
    justify-content: center;
    margin: 10px;
  }
  .wrapSquare {
    display: flex;
    position: relative;
    overflow: hidden;
    font-size: 1.25rem;
    align-items: center;
    flex-shrink: 0;
    user-select: none;
    justify-content: center;
    margin-top: 0;
    margin-bottom: 0;
    margin-left: 0;
    //margin-right: 10px;
  }
  .wrapRounded{
    border-radius: 5px;
    display: flex;
    position: relative;
    overflow: hidden;
    font-size: 1.25rem;
    align-items: center;
    flex-shrink: 0;
    user-select: none;
    justify-content: center;
    margin-top: 0;
    margin-bottom: 0;
    margin-left: 0;
    //margin-right: 10px;
  }
  .title {
    font-weight: 500;
  }
  .titleSquare {
    font-weight: 500;
    font-size: 18px;
  }
  .child {
    padding: 5px;
    display: flex;
    align-items: center;
  }
  .small {
    width: 20px;
    height: 20px;
  }
  .midium {
    width: 40px;
    height: 40px;
  }
  .large {
    width: 60px;
    height: 60px;
  }