.flag {
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  width: 4vh;
  height: 4vh;
  border-radius: 6px !important;
  transition: border-radius 350ms cubic-bezier(.42,0,.58,1) !important;
  &_round {
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
    width: 4vh;
    height: 4vh;
    border-radius: 50% !important;
    transition: border-radius 350ms cubic-bezier(.42,0,.58,1) !important;
  }
  &_circular_white {
    color: #ffffff !important;
  }
  &_circular_gray {
    color: gray !important;
  }
  & > span {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

@media (max-height: 800px) {
  .flag_icon {
    font-size: 0.8em !important;
  }
};