.pop-container {
  right: 10px !important;
  left: auto !important;
}
.filter-container {
  position: relative;
}

.active-filters {
  height: 10px;
  width: 10px;
  background-color: red;
  border-radius: 50%;
  position: absolute;
  right: -1px;
  top: -1px;
}