.center-table {
  display: flex !important;
  justify-content: center !important;
  align-items: center;
  flex-direction: column;
  margin-top: 30px;
}

.center-list {
  width: 480px !important;
}