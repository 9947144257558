.deliver {
  &_container {
    display: flex;
    flex-direction: column;
  }
  &_mda {
    display: flex;
    flex-direction: column;
    &_list {
      align-items: center;
      display: flex;
      height: auto !important;
      width: 100% !important;
      justify-content: center;
      max-height: 348px !important;
      overflow-y: auto !important;
      & > div {
        width: 100% !important;
        background-color: #fff !important;
      }
    }
  }
  &_products {
    width: 100% !important;
    display: flex;
    flex-direction: column;
    max-height: 300px !important;
    overflow-y: auto !important;
  }
}
