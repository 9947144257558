//Sizes Variables
$size-10: 10px !default;
$size-20: 20px !default;
$size-30: 30px !default;
$size-40: 40px !default;
$size-50: 50px !default;
$size-60: 60px !default;
$size-70: 70px !default;
$size-80: 80px !default;
$size-90: 90px !default;
$size-100: 100px !default;
$size-120: 120px !default;