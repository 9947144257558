.deliver {
  width: 80%;
  margin:  0 auto;

  &__client {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
  }
}

.product-list {
  // max-height: 40vh;
  overflow: auto;
  margin-bottom: 20px;
}

.mda-list {
  max-height: 35vh;
  overflow: auto;
}

.refund_product {
  min-height: 75px !important;
}

.refund_product_qty {
  width: 45%;
}

.estado_morado {
  background-color: #8051A1 !important;
  color: white;

  .scanInput {
    background-color: transparent !important;
    color: white;
  }

  .inputNumber_number {
    background-color: transparent !important;
    color: white;
  }
}

.estado_rojo {
  background-color: #F05B70 !important;
  color: white;

  .scanInput {
    background-color: transparent !important;
    color: white;
  }

  .inputNumber_number {
    background-color: transparent !important;
    color: white;
  }
}

.estado_amarillo {
  background-color: #F7C379 !important;
  color: white;

  .scanInput {
    background-color: transparent !important;
    color: white;
  }

  .inputNumber_number {
    background-color: transparent !important;
    color: white;
  }
}

.estado_azul {
  background-color: #23A1DB !important;
  color: white;

  .scanInput {
    background-color: transparent !important;
    color: white;
  }

  .inputNumber_number {
    background-color: transparent !important;
    color: white;
  }

}

.scanInput {
  border: 0;
  text-align: center;
  outline: none;
}