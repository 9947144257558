.tollbar-header {
  display: flex;
  justify-content: space-between !important;
  align-items: center;
  flex-direction: row !important;
} 
.center-list {
  min-width: 480px !important;
}

.center-table {
  display: flex !important;
  justify-content: center !important;
  align-items: center;
  flex-direction: column;
  margin-top: 30px;
}
