.search_bar fieldset {
  border-radius: 20px;
}
.search_bar {
  & > div {
    padding-left: 0 !important;
  }
  & > div > div > button {
    &:focus {
      outline: none;
    }
  }
  &_modebtn {
    margin-left: 4px !important;
    border-radius: 50% !important;
    width: 38px !important; height: 38px !important;
  }
}
