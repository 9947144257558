.login {
  &_container {
    position: absolute;
    height: 100vh;
    width: 100%;
    display: flex;
    flex-direction: column;
  }

  &_img {
    width: 100% !important;
    & > img {
      width: 300px !important;
    }
  }
  &_form {
    width: 100%;
    z-index: 20;
    height: 75% !important;
    & > div {
      margin-top: 0.8em !important;
    }
    & > div > span {
      font-size: 1.4em !important ;
    }
  }
}

@media (min-width: "1200px") {
  .login_container {
    height: auto;
    margin-top: 8em;
    position: relative;
  }
  .login_form {
    width: 400px !important;
    margin: 0px auto;
  }
}
