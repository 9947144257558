.custom-inputs {
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: hsl(0,0%,100%);
  border-color: hsl(0,0%,80%);
  border-radius: 4px;
  border-style: solid;
  border-width: 1px;
  cursor: default;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  min-height: 38px;
  outline: 0 !important;
  position: relative;
  -webkit-transition: all 100ms;
  transition: all 100ms;
  box-sizing: border-box;
  padding: 5px 10px;
  width: 100%;
}

.fixed-cell {
  position: sticky;
  left: 0;
  z-index: 1;
  background-color: white;
}

.order-cell {
  padding: 0 5px !important;
  text-align: center !important;
  white-space: nowrap;
}

.react-datepicker {
  &__input-container input {
    min-height: 38px;
    width: 100%;
    background-color: white;
    border-color: #cccccc;
    border-radius: 4px;
    border-style: solid;
    border-width: 1px;
    cursor: default;
  }
}

.p-5 {
  padding: 5px !important;
}


.selected-order {
  background-color: rgba(204, 204, 204, 0.88);
}

.opt-title {
  font-size: 10px;
}

.opt-icon {
  color: #cccccc;
}

.sortable {
  font-size: 16px !important;
  color: #F05B6F !important;
}

.react-datepicker-wrapper {
  border: 1px solid darkgray;
  border-radius: 3px;
  padding: 3px;
}