.file {
  &_icon {
    & > svg {
      color: #313c52;
    }
    font-size: 2em !important;
  }
  &_text {
    color: #313c52 !important;
    & > span {
      font-size: 16px !important;
    }
  }
}
