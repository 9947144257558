.input-container {
  flex-direction: column;
  justify-content: center;
  display: flex;
  height: 100%;

  &__inside-input {
    background-color: #EFEFF4;
    border-radius: 12px;
    padding: 0.5rem;
  }
}