/* .MuiBackdrop-root {
    top: 48px !important;
}

.MuiDrawer-paperAnchorTop {
    top: 48px !important;
    background-color: #f6f6f7 !important
} */

.root {
  //display: flex;
  //flex-wrap: wrap;
  //justify-content: center;
  overflow: hidden;
  width: auto;
  padding-right: 5px;
  padding-left: 5px;
}

.bigAvatar {
  margin: 10px !important;
  width: 60px !important;
  height: 60px !important;
  //margin-right: 0 !important;
}
.bgBlueDarck {
  color: #fff;
  background-color: #313c52;
}
.flex {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.bgGrey {
  background-color: #f6f6f7;
  width: auto !important;
}
.mt {
  margin-top: 48px !important;
}
.pd {
  padding: 16px;
  background-color: #fff;
}
.list {
  width: auto;
}
.fullList {
  width: auto;
}
.option-title{
    font-weight: bold !important;
}
.mt-101{
  margin-top: 112px;
}

.drawer-client-container {
  border-radius: 0 0 8px 8px !important;
}