.update-container-list{
    background-color: #fff;

    .update-time {
        font-size: 13px;
        color: #B6BEC6;
        font-weight: normal;
    }
}

.update-avatar-bg{
    background-color: #EFEFF4 !important;
    width: 48px !important;
    height: 48px !important;
}

.update-title{
    color: #313C52 !important;
    font-size: 20px !important;
    font-weight: 600 !important;
}
.update-subtitle{
    color: #64707A !important;
    font-size: 16px !important;
    font-weight: 500 !important;
}
.update-icon{
    color: #313C52;
    font-size: 24px;
}
.empty-email{
    height: 60vh;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
.colorBlue{
    color: #303c52 !important;
}
.activity-title {
    font-size: 16px !important;
    color: #313c52 !important;
    font-weight: 600 !important;
}