.quote {
    &__title {
        font-size: 20px !important;
        color: #313C52 !important;
        font-weight: 600 !important;
    }
    &__subtitle {
        font-size: 13px !important;
        color: #B6BEC6 !important;
    }
    &__money {
        font-size: 20px !important;
        color: #64707A !important;
        text-align: end;
        font-weight: 500 !important;
    }
}

.modalDetail {
    &_header {
      color: #64707a !important;
      font-size: 24px !important;
      text-align: left !important;
    }
    &_appBar {
      box-shadow: 0 1px 6px #00000029 !important;
      z-index: 1101 !important;
    }
  }

.quote-detail {
  &__title {
      font-size: 20px !important;
      color: #313C52 !important;
      font-weight: 600 !important;
  }
  &__subtitle {
      font-size: 13px !important;
  }
  &__subtitle-left {
      font-size: 13px !important;
  }
  &__subtitle-right {
      font-size: 13px !important;
      text-align: end;
  }
}