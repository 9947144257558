.salesDetail-text-primary{
    color: #64707A !important;
    font-size: 16px !important;
    text-align: left !important;
}
.salesDetail-text-secondary{
    color: #B6BEC6 !important;
    font-size: 13px !important;
}
.salesDetail-list-text-left{
    color: #64707A !important;
    font-size: 15px !important;
    font-weight: 400;
}
.salesDetail-list-text-right{
    color: #64707A !important;
    font-size: 16px !important;
    font-weight: 200;
}
.salesDetail-list-selected{
    background-color: #F6F6F7 !important;
}
.loader-sm {
    width: 28px !important;
    height: initial !important;
}
