.hits-table {
    width: 100%;
    border-collapse: collapse;
}

.hits-table th, .hits-table td {
    // border-bottom: 1px solid #dddddd;
    text-align: left;
    padding: 0 8px;
}

/* Estilo para filas pares */
.hits-table tr:nth-child(even) {
    background-color: #F6F6F7;
    cursor: pointer;
}

/* Estilo para filas impares */
.hits-table tr:nth-child(odd) {
    background-color: #ffffff;
    cursor: pointer;
}

.product-table  {
    height: calc(100vh - 190px);
    overflow: auto;
}

.product-table-head {
    position: sticky;
    top: 0;
    padding: 4px;
    height: 56px;
    z-index: 1;
    background-color: #F6F6F7;
}

.hits-table .product-table-head tr {
    background-color: #f1f1f1;
    margin: 5px 0;
}

.hits-table .product-table-head tr th {
    position: relative;
}

.hits-table .product-table-head tr th::after {
    content: '';
    height: 60%;
    border-right: 1px solid #dddddd;
    position: absolute;
    top: 10px;
    right: 0;
}

.hits-table .product-table-head input {
    border: none;
    height: 24px;
    border-radius: 5px;
    outline: none;
    padding: 0 4px;
}

.hits-table td.image-td {
    padding-left: 0;
}

.no-results {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
}

