
.timer-container {
  display: flex;
  background-color: white;
  border-radius: 21px;
  color: #64707A;
  border: 1px solid #E5E5E5;
  z-index: 9999;
  font-size: 20px;
  padding: 5px 20px;
  align-items: center;
  justify-content: space-between;
  width: auto;
  gap: 10px;
  /* transition: transform 0.2s ease-in-out; */
}

.global-style {
  position: relative !important;
}

.hide-left {
  transform: translateX(-168px);
}

.fab-btn {
  height: 38px !important;
  width: 38px !important;
}

@-webkit-keyframes rotating /* Safari and Chrome */ {
  from {
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes rotating {
  from {
    -ms-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -ms-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
.rotating {
  -webkit-animation: rotating 2s linear infinite;
  -moz-animation: rotating 2s linear infinite;
  -ms-animation: rotating 2s linear infinite;
  -o-animation: rotating 2s linear infinite;
  animation: rotating 2s linear infinite;
}