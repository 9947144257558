.guide-list {
    background-color: transparent;
    width: 100%;
}

.guide {
    &__title {
        font-size: 20px !important;
        color: #313C52 !important;
        font-weight: 600 !important;
    }
    &__subtitle {
        font-size: 13px !important;
        color: #B6BEC6 !important;
    }
    &__money {
        font-size: 20px !important;
        color: #64707A !important;
        text-align: end;
        font-weight: 500 !important;
    }
}

.modalDetail {
    &_header {
      color: #64707a !important;
      font-size: 24px !important;
      text-align: left !important;
    }
    &_appBar {
      box-shadow: 0 1px 6px #00000029 !important;
      z-index: 1101 !important;
    }
  }

.guide-detail {
  &__title {
      font-size: 20px !important;
      color: #313C52 !important;
      font-weight: 600 !important;
  }
  &__subtitle {
      font-size: 13px !important;
  }
  &__subtitle-left {
      font-size: 13px !important;
  }
  &__subtitle-right {
      font-size: 13px !important;
      text-align: end;
  }
}

.close-icon,
.check-icon {
    background-color: white !important;
    color: #64707A !important;
}

.avatar-square {
    border-radius: 0 !important;
}

.guide-date {
    background-color: #F6F6F7;
    color: #64707A;
    padding: 2px 5px;
    border-radius: 10px;
    border: none;
    max-width: 150px;
}
.dp-container .react-datepicker-wrapper {
    max-width: 150px;
    border: none;
}

.product {
    &__title {
        color: #313C52 !important;
        font-size: 16px !important;
        display: initial !important;
    }

    &__price {
        color: #313C52 !important;
        font-size: 20px !important;
}   }

.max-width-100 {
    max-width: 100px;
    cursor: pointer;
}