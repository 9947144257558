.comment-title{
    color: #313C52 !important;
    font-size: 20px !important;
    font-weight: 600 !important;
}
.comment-subtitle{
    color: #B6BEC6 !important;
    font-size: 13px !important;
    font-weight: 500 !important;
}
.comment-comment{
    color: #64707A;
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 1rem;
}