.btn-details{
  padding: 18px !important;
  font-size: 15px !important;
  color: white !important;
  border-radius: 17px !important;
  min-width: 172px !important;
  border: 47px !important;
  box-shadow: 0 3px 6px #00000029 !important;
  text-transform: uppercase !important;
  background-color: #23A1DB !important;
}

.toolbar {
  position:  relative !important;
}