.activity-indicator {
  background-color: #f6f6f7;
  padding: 0.2rem 0.6rem;
  border-radius: 50px;
  color: #313c52;
}
.input-title {
  border: 1px solid #E5E5E5;
  padding: 5px 10px 5px 0px;
  border-radius: 16px;
  cursor: pointer;
  min-height: 35px;
  padding: 5px 15px;
  outline: none !important;
}

.detailInfo {
  width: 100% !important;
  min-height: 85vh !important;
  display: flex !important;
  flex-direction: column !important;
  background-color: #EFEFF4 !important;
  &_customer {
    width: 100% !important;
    height: auto !important;
    padding: 0.8em 2em !important;
    display: flex;
    align-items: center;
    justify-content: space-between;
    & > span {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }
    & > div {
      align-items: flex-start !important;
    }
    & > span > span {
      font-size: 14px !important;
    }
    &_search {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      & > div {
        width: 100% !important;
      }
    }
    &_flag {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 54px;
      height: 54px;
      background-color: #ffffff;
      border-radius: 17px !important;
    }
    &_popover {
      padding: 1em;
      display: flex;
      align-items: center;
      justify-content: space-around;
      -webkit-transition: width 1s; /* For Safari 3.1 to 6.0 */
      transition: width 1s;
      & > span {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        margin: 0.4em !important;
      }
    }
    &_popoend {
      -webkit-transition: width 1s; /* For Safari 3.1 to 6.0 */
      transition: width 1s;
    }
    &_paper {
      & > div {
        border-radius: 14px !important;
      }
    }
  }
  &_products {
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-bottom: 20vh !important;
    & > button {
      border-bottom: solid #c7c7c7 1px !important;
      &:first-child {
        border-top: solid #c7c7c7 1px !important;
      }
    }
  }
  &_discount {
    & > span {
      font-size: 18px;
      color: #23a1db;
      text-align: right;
      text-decoration: none;
      font-family: "Roboto Medium";
      &:first-child {
        color: #b6bec6 !important;
        font-size: 15px !important;
        text-align: left !important;
        font-family: "Roboto" !important;
        text-decoration: line-through !important;
        margin-right: 0.4em !important;
      }
    }
  }
  &_comment {
    width: 25px;
    height: 25px;
    background-color: #b6bec6 !important;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    & > svg {
      color: #ffffff !important;
    }
  }
  &_collapse {
    width: 100%;
    height: auto;
    padding: 0.6em 1.6em !important;
    display: flex;
    flex-direction: column;
    background-color: #f6f6f7 !important;
    & > div {
      width: 100% !important;
      height: 48px !important;
      display: flex;
      align-items: center;
      margin-bottom: 0.8em !important;
    }
  }
  &_qtys {
    justify-content: space-between;
    & > div {
      width: 40vw;
    }
  }
}

.filter-header {
  border-bottom: 2px solid #efeff4;
}

.detailInfo_customer_popover {
  overflow-x: auto !important;
}