.refund {
  &_tabs {
    background-color: #f7fbfc !important;
    width: 100% !important;
  }
  &_container {
    display: flex !important;
    flex-direction: column !important;
  }
  &_layout {
    display: flex !important;
    flex-wrap: wrap !important;
    width: 100% !important;
    & > div {
      padding: 0.4em 0 !important;
    }
  }
  &_products {
    display: flex;
    flex-direction: column !important;
    max-height: 286px !important;
    width: 100% !important;
    overflow-y: auto !important;
    scroll-behavior: smooth !important;
    &_head {
      font-size: 14px !important;
      color: #313c52 !important;
      margin-top: 0.4em !important;
    }
    & > div {
      border-bottom: solid #e5e5e5 1px;
      &:last-child {
        border-bottom: none !important;
      }
    }
  }
  &_product {
    height: 100% !important;
    min-height: 94px !important;
    max-height: 100px !important;
    background-color: #ffffff !important;
    display: flex !important;
    & > div {
      padding: 0.4em;
    }
    &_qty {
      display: flex !important;
      align-items: center !important;
      justify-content: center !important;
      padding: 1.6em !important;
      min-width: 25% !important;
    }
    &_stock {
      display: flex;
      justify-content: center !important;
      align-items: center;
      font-size: 20px !important;
      font-family: "Roboto Medium";
      border-right: solid #e5e5e5 1px !important;
      flex-direction: column;
      min-width: 64px !important;
      & > span {
        font-size: 10px !important;
      }
    }
    &_title {
      font-size: 17px !important;
      /* white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      max-width: 325px !important; */
    }
    &_price {
      font-size: 16px !important;
    }
    &_detail {
      width: 100% !important;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }
    &_qty {
      width: 30%;
    }
    &_desc {
      font-size: 12px;
    }
  }
  &_totals {
    font-size: 16px !important;
    color: #313c52 !important;
    display: flex !important;
    flex-direction: row-reverse !important;
    align-items: center !important;
  }
  &_fde {
    max-height: 288px !important;
    width: 100% !important;
    overflow-y: auto !important;
    scroll-behavior: smooth !important;
    &_head {
      margin-top: 0.4em !important;
      display: flex;
      justify-content: space-between;
      align-items: center;
      & > span {
        font-size: 14px !important;
        color: #313c52 !important;
      }
    }
    &_icon {
      width: 7%;
      height: 100% !important;
      display: flex;
      align-items: center;
      justify-content: center;
      & > svg {
        font-size: 20px !important;
        // color: #ffffff !important;
      }
    }
    &_totals {
      align-items: center;
      color: #313c52 !important;
      display: flex;
      flex-direction: row-reverse;
      font-family: 'Roboto Light' !important;
      font-size: 13px !important;
       & > span {
         margin-left: 1.4em !important;
       }
    }
    & > li {
      height: 64px !important;
      padding: 0 !important;
      background-color: #ffffff !important;
      border-bottom: solid 1px #c6c6c6 !important;
      &:last-child {
        border-bottom: none !important;
      }
    }
  }
  &_toRefund {
    display: flex !important;
    flex-direction: column !important;
    width: 100% !important;
    border: solid 1px #c6c6c6;
    border-radius: 0.4em !important;
    & > li {
      min-height: 58px !important;
      border-bottom: solid 1px #c6c6c6 !important;
      padding: 10px !important;
      &:last-child {
        border-bottom: none !important;
      }
    }
    &_container {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      & > div > button {
        &:first-child {
          & > span {
            text-align: start !important;
            text-transform: capitalize;
            line-height: 0.8em !important;
            font-size: 20px;
            color: #313c52;
          }
        }
        &:last-child {
          & > span {
            text-align: start !important;
            text-transform: capitalize;
            line-height: 0.8em !important;
            font-size: 13px;
            color: #646f79;
          }
        }
      }
    }
  }
  &_controls {
    align-items: center;
    display: flex;
    height: auto !important;
    padding: 0.4em 0 0.4em 10px !important;
    width: 100% !important;
  }
  &_confirm {
    position: fixed;
    right: 4vw;
    bottom: 6vh;
    width: 128px !important;
    height: 128px !important;
    display: flex !important;
    align-items: center;
    justify-content: center;
    & > button {
      &:active,
      &:focus {
        outline: none !important;
      }
    }
    &_desktop {
      width: 100% !important;
      display: flex;
      flex-direction: row-reverse !important;
      align-items: center;
      & > button {
        display: flex;
        flex-direction: column !important;
        margin-left: 1em !important;
        padding: 14px;
      }
    }
  }
}

.infoDetail {
  width: 100% !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  margin: 0.6em 0 !important;
  border: solid 1px #c6c6c6 !important;
  border-radius: 0.6em !important;
  & > li {
    &:last-child {
      border-left: solid 1px #c6c6c6 !important;
    }
  }
}

.rotateY {
  transform: rotateY(360deg) !important;
}

.full-width {
  width: 100% !important;
  max-width: 100% !important;
}
