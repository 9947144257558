.filter-title{
    font-size: 20px !important;
    color: #313C52 !important;
    font-weight: 600 !important;
}

.filter-item-title{
    font-size: 15px !important;
    color: #313C52 !important;
    font-weight: 600 !important;
}
.filter-item-subtitle{
    font-size: 13px !important;
    color: #23A1DB !important;
}

.filter-item-subItem{
    font-size: 16px !important;
    color: #64707A !important;
}

.filter-item-checkbox{
    color: #313C52 !important;
}

.filter-item-bg{
    background-color: #F6F6F7 !important;
}

.filter-list{
    padding-top: 0 !important;
    padding-bottom: 0 !important;
}