@import 'settings/variable';  //themes' colors
@import 'mixin/font-composer.scss';

//vendor...
@import './vendor';

//base...
@import 'generic/normalize.scss';
@include fontFace('Roboto', 'Roboto-Regular');
@include fontFace('Roboto Light', 'Roboto-Light');
@include fontFace('Roboto Medium', 'Roboto-Medium');
@include fontFace('Roboto Bold', 'Roboto-Bold');
@include fontFace('Roboto Black', 'Roboto-Black');
@include fontFace('Segoe-UI', 'Segoe-UI');
@include fontFace('Monaco', 'Monaco');


@import './global/_global-dir';

@import 'generic/box-sizing.scss';
@import './base/scaffolding';         // styles of base elements
@import './base/typography';          // base styles of h1-h6, p, span
@import './base/base';
@import './ui/index';


@import './component/modal';                  // components/Modal.js ('reactstrap')
@import './component/pagination';             // components/Pagination.js ('reactstrap')
@import './component/load';
@import './component/customizer';

@import './component/pos';                      // Pos
@import './component/pos_login.scss';           // components/LoginForm
@import './component/pos_file.scss';            // components/Files
@import './component/pos_product';              // components/Product
@import './component/pos_category';             // components/Category
@import './component/pos_customer';             // components/Customer
@import './component/pos_offer';                // components/Discount
@import './component/pos_cart';                 // components/Cart
@import './component/pos_sell';                 // components/sell
@import './component/pos_item_container';       // components/PosItemContainerAction
@import './component/pos_tickets.scss';         // components/Tickets
@import './component/alert-box';                // components/alertBox
@import './component/checkOut';                 // components/checkOut
@import './component/pos_payment';              // components/Payment
@import './component/MobileActionsBar.scss';    // components/MobileActionsBar
@import './component/pos_balance.scss';
@import './component/search-bar.scss';
@import './component/topbar';                 // components/topbar/*

// Animations
@import './component/animations';               // Pos/components/Cart

//pages...
@import 'containers/account';              // pages/account/* (except profile)
@import 'containers/profile';              // pages/account/profile/*
@import 'containers/clients';              // pages/clients*

//objects...
@import 'objects/layout.scss';        // layout settings

.overflow-y-visible {
  overflow-y: visible !important;
}