.coming-soon{
    height: 60vh;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    font-family: 'Roboto';
}
.coming-soon_title{
    margin-top: 20px;
}