.tickets {
  &_modal {
    max-width: 24vw;
    &_body {
      max-height: 60vh !important;
      overflow-y: auto;
    }
  }
  &_table {
    color: black;
    width: 6cm;
    line-height: 0.9em;
    font-size: unset;
    font-size: 16px !important;
    margin-top: 1em;
    margin-left: 1em;
    font-weight: 500;
    font-family: 'Roboto Black' !important;
    &_logo {
      width: 80% !important;
      color: #000000 !important;
    }
    &_org {
      font-size: 1.2em;
    }
    &_type {
      font-size: 0.9em;
      font-style: italic;
      color: #000000 !important;
    }
    &_client {
      max-width: 200px !important;
      overflow: hidden !important;
      text-align: center !important;
      white-space: nowrap !important;
      text-overflow: ellipsis !important;
    }
    &_clientBill {
      text-align: center !important;
    }
    &_clientBillH {
      text-align: center !important;
      max-width: 100px !important;
    }
    &_subtitle {
      font-size: 0.6em;
      color: #000000 !important;
    }
    &_desc {
      padding-left: 1em;
      font-size: 0.85em;
      text-align: left;
      width: 80px;
    }
    &_desc_qty {
      font-size: 1.4em !important;
      color: #000000 !important;
      font-weight: 600 !important;
    }
    &_qty {
      text-align: left !important;
    }
    &_subTotal {
      font-size: 0.9em;
      padding: 0.4em 0.4em;
      width: 54px !important;
      text-align: right !important;
      color: #000000 !important;
    }
    &_totales {
      font-size: 0.9em;
      padding: 0.4em 0.2em;
      width: 54px !important;
      text-align: right !important;
      border-left: solid 1px #565656 !important;
      color: #000000 !important;
    }
    &_subtotal {
      font-size: 1em !important;
      text-align: right !important;
      color: #000000 !important;
    }
    &_total {
      font-size: 1.2em !important;
      text-align: right !important;
      color: #000000 !important;
    }
    &_title_product {
      font-size: 0.8em !important;
    }
    & > thead > tr > th {
      text-align: center !important;
    }
  }
}
.ticketsCC {
  &_modal {
    max-width: 24vw;
    &_body {
      max-height: 60vh !important;
      overflow-y: auto;
    }
  }
  &_table {
    color: black;
    width: 6cm;
    line-height: 1.4em;
    font-size: unset;
    // font-family: "Monaco" !important;
    font-size: 16px !important;
    margin-top: 1em;
    margin-left: 1em;
    font-weight: 500;
    &_logo {
      width: 60% !important;
    }
    &_org {
      font-size: 1.2em;
    }
    &_type {
      font-size: 0.8em;
      font-style: italic;
      color: #000000 !important;
    }
    &_client {
      max-width: 200px !important;
      overflow: hidden !important;
      text-align: center !important;
      white-space: nowrap !important;
      text-overflow: ellipsis !important;
    }
    &_subtitle {
      font-size: 0.6em;
    }
    &_desc {
      padding-left: 1em;
      font-size: 0.85em;
      text-align: left;
      width: 80px;
    }
    &_desc_qty {
      font-size: 1.4em !important;
      font-weight: 600 !important;
    }
    &_qty {
      text-align: left !important;
    }
    &_subTotal {
      font-size: 0.9em;
      padding: 0.4em 0.4em;
      width: 54px !important;
      text-align: right !important;
      color: #000000 !important;
    }
    &_totales {
      font-size: 0.9em;
      padding: 0.4em 0.2em;
      width: 54px !important;
      text-align: right !important;
      border-left: solid 1px #565656 !important;
      color: #000000 !important;
    }
    &_subtotal {
      font-size: 1em !important;
      text-align: right !important;
      color: #000000 !important;
    }
    &_total {
      font-size: 1.2em !important;
      text-align: right !important;
      color: #000000 !important;
    }
    &_title_product {
      font-size: 0.8em !important;
      color: #000000 !important;
    }
    & > thead > tr > th {
      text-align: center !important;
    }
  }
}

.table_movimientos {
  margin-left: 2px;
  width: 100% !important;
  font-size: 18px !important;
  color: #000000 !important;
  // font-family: "Monaco" !important;
  line-height: 1;
  & > tbody > tr {
    border-bottom: solid #000 3px;
  }
}

.table_detalles {
  margin-left: 4px;
  width: 100% !important;
  font-size: 15px !important;
  // font-family: "Monaco" !important;
  line-height: 1;
  border-bottom: #000 2px;
  border-style: none none solid none;
  color: #000000 !important;
  &:last-child {
    border-style: none none double none !important;
  }
}

.lineTh {
  line-height: 1em !important;
  color: #000000 !important;
}

.titleTh {
  max-width: 120px !important;
  overflow: hidden !important;
  white-space: nowrap !important;
  text-overflow: ellipsis !important;
  color: #000000 !important;
}

.rowTh {
  padding-bottom: 0.5em !important;
  border-bottom: solid 1px #000000;
}

.bill {
  &_desc {
    width: 100% !important;
    line-height: 1.1em;
    font-size: 9px;
    color: #000000 !important;
  }
  &_table {
    color: #000000 !important;
    &_head {
      color: #000000 !important;
      & > th {
        font-size: 10px !important;
        color: #000000 !important;
      }
    }
  }
}

.text-area-container {
  word-break: break-word;
  font-size: 9px !important;
}

.firma {
  width: 100% !important;
  height: 2em !important;
  text-align: center;
  margin-top: 4em !important;
  border-top: dashed 1px #000000 !important;
}

