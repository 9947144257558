.img-responsive {
  height: auto !important;
}

.data-container {
  max-width: 80%;
  margin: 0 auto;
}

.qty-input {
  font-size: 16px;
}

.deliver-notes {
  padding: 5px 10px;
  border-radius: 5px;
  border-color: darkgray;
}