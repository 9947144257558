.activity-container {
  background-color: white;
}

.header-title {
  font-size: 20px;
  font-weight: 500;
  color: #313C52;
  line-height: 24px;
}

.title {
  font-size: 15px;
  font-weight: 500;
  color: #313C52;
  line-height: 18px;
}

.sub-title {
  font-size: 13px;
  color: #64707A;
  line-height: 15px;
}

.status-container {
  display: flex;
  justify-content: space-around;
  align-items: center;
  gap: 10px;
  border: 1px solid #E5E5E5;
  padding: 5px 10px 5px 0px;
  border-radius: 16px;
  cursor: pointer;
  min-width: 160px;
  height: 45px;
  width: 100%;
}

.detailInfo {
  width: 100% !important;
  min-height: 85vh !important;
  display: flex !important;
  flex-direction: column !important;
  background-color: #EFEFF4 !important;
  &_customer {
    width: 100% !important;
    height: auto !important;
    padding: 0.8em 2em !important;
    display: flex;
    align-items: center;
    justify-content: space-between;
    & > span {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }
    & > div {
      align-items: flex-start !important;
    }
    & > span > span {
      font-size: 14px !important;
    }
    &_search {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      & > div {
        width: 100% !important;
      }
    }
    &_flag {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 54px;
      height: 54px;
      background-color: #ffffff;
      border-radius: 17px !important;
    }
    &_popover {
      padding: 1em;
      display: flex;
      align-items: center;
      justify-content: space-around;
      -webkit-transition: width 1s; /* For Safari 3.1 to 6.0 */
      transition: width 1s;
      & > span {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        margin: 0.4em !important;
      }
    }
    &_popoend {
      -webkit-transition: width 1s; /* For Safari 3.1 to 6.0 */
      transition: width 1s;
    }
    &_paper {
      & > div {
        border-radius: 14px !important;
      }
    }
  }
  &_products {
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-bottom: 20vh !important;
    & > button {
      border-bottom: solid #c7c7c7 1px !important;
      &:first-child {
        border-top: solid #c7c7c7 1px !important;
      }
    }
  }
  &_discount {
    & > span {
      font-size: 18px;
      color: #23a1db;
      text-align: right;
      text-decoration: none;
      font-family: "Roboto Medium";
      &:first-child {
        color: #b6bec6 !important;
        font-size: 15px !important;
        text-align: left !important;
        font-family: "Roboto" !important;
        text-decoration: line-through !important;
        margin-right: 0.4em !important;
      }
    }
  }
  &_comment {
    width: 25px;
    height: 25px;
    background-color: #b6bec6 !important;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    & > svg {
      color: #ffffff !important;
    }
  }
  &_collapse {
    width: 100%;
    height: auto;
    padding: 0.6em 1.6em !important;
    display: flex;
    flex-direction: column;
    background-color: #f6f6f7 !important;
    & > div {
      width: 100% !important;
      height: 48px !important;
      display: flex;
      align-items: center;
      margin-bottom: 0.8em !important;
    }
  }
  &_qtys {
    justify-content: space-between;
    & > div {
      width: 40vw;
    }
  }
}

.custom-input-group {
  border: 1px solid #e5e5e5;
  padding: 0.2rem 1rem;
  border-radius: 20px;
  min-width: 140px;
  width: 100%;
  overflow: auto !important;

  &__inside-input {
    border: none;
    background-color: transparent;
    padding: 5px 0;
    font-size: 15px;

    &:focus {
      outline: none;
    }
  }
}

.headerTitle {
  align-items: center;
  background-color: #f6f6f7 !important;
  box-shadow: 0px 3px 6px #00000029 !important;
  color: #64707A !important;
  display: flex;
  font-family: "Roboto Medium";
  font-size: 21px !important;
  height: 48px !important;
  justify-content: center;
  width: 100% !important;
}
