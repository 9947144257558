.toggle-btn {
  background-color: #E5E5E5;
  border: 1px solid #E5E5E5;
  border-radius: 99px;
  width: 40px;
  height: 24px;
  transition: background-color 0.1s ease, border-color 0.2s ease;
  cursor: pointer;
  // box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.748);
  position: relative;
}

.toggle-btn .thumb {
  height: 15px;
  width: 15px;
  background-color: #fff;
  border-radius: 99px;
  transform: translateX(0);
  transition: left 0.15 ease;
  position: absolute;
  left: 3px;
  top: 50%;
  transform: translateY(-50%);
}

.toggle-btn.toggled {
  background-color: #23A1DB;
}

/* .toggle-btn:hover {
  border-color: #6f6f6f;
} */

.toggle-btn.toggled .thumb {
  left: calc(50px - 30px);
}

.toggle-container {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
}