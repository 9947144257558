.headerTitle_container{
    font-family: 'Roboto';
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 8px;
    width: 100%;
    .headerTitle_title{
        font-size: 20px;
        font-weight: bold;
        color: #313C52;
    }
}