.container {

  @media screen and (min-width: 768px) {
    max-width: 100% !important;
    height: calc(100vh - 64px);
  }
}

.container__wrap {
  padding-left: 0;
  padding-top: 90px;
  min-height: 100vh;
  transition: padding-left 0.3s;

  @include themify($themes) {
    background: themed('colorBackgroundBody');
  }

  @media screen and (min-width: 576px) {
    padding-left: 250px;
  }

  @media screen and (max-width: 576px) {
    padding-top: 60px;
  }
}

.layout {

  &.layout--collapse {

    & + .container__wrap {
      padding-left: 0;

      @media screen and (min-width: 576px) {
        padding-left: 60px;
      }
    }
  }

  &.layout--top-navigation{

    & + .container__wrap{

      @media screen and (min-width: 576px) {
        padding-left: 0;
      }
    }
  }
}
.align-center {
  top: 50% !important;
  left: 50% !important;
  transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
}

#main-container-desktop {
  // padding: 0 !important;
  padding: 10px 0 0 0 !important;
  overflow-x: hidden;
}

/* .ag-header-row:first-child {
  display: none !important;
} */
