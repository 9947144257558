.factura {
  &_item {
    &_title {
      color: #313c52;
      font-size: 20px !important;
      font-family: "Roboto Medium";
    }
    &_date {
      color: #b6bec6 !important;
      font-size: 13px !important;
    }
    &_total {
      color: #313c52 !important;
      font-size: 20px !important;
      font-family: "Roboto Medium";
      &_cancelado {
        color: #b6bec6 !important;
        font-size: 20px !important;
        font-family: "Roboto Medium";
      }
    }
  }
  &_icon {
    width: 24px !important;
    height: 24px !important;
  }
}
