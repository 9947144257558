.appBar {
  position: 'relative',
}
.dialog-bg{
  background-color: #EFEFF4;
}

.toolbar-title {
  margin-left: 0px !important;
}

.title-type{
  color: #64707A !important;
  font-size: 18px !important;
  font-weight: 500 !important;
}

.toolbar-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

