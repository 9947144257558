/* Styles for the slider component */
.slider {
    height: 10px;
    width: 100%;
    background-color: #ddd;
    border-radius: 5px;
    margin-top: 10px;
}

/* Styles for the slider thumb */
.slider .thumb {
    height: 20px;
    width: 20px;
    border-radius: 50%;
    background-color: #24a2db;
    cursor: grab;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
}

/* Styles for the slider active state */
.slider .thumb.active {
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
}

.horizontal-slider {
    width: 100%;
    max-width: 500px;
}

.slider .example-track {
    top: 20px;
    height: 10px;
}

.example-track.example-track-0 {
    background: #ddd;
    top: 0;
    border-radius: 50%;
}

.slider .example-track.example-track-1 {
    background: #24a2db !important;
    top: 0;
}

.example-track.example-track-2 {
    background: #ddd;
    top: 0;
    border-radius: 50%;
}

.example-track {
    background-color: #24a2db;
}

.horizontal-slider .example-thumb {
    top: 1px;
    width: 50px;
    height: 48px;
    line-height: 38px;
    border-radius: 50%;
    font-size: 0.9em;
    text-align: center;
    background-color: black;
    color: white;
    cursor: pointer;
    border: 5px solid gray;
    box-sizing: border-box;
}