.google-maps-map {
    width: 100%;
    height: 50vh;
}

.google-maps-search-box {
    position: relative;
    margin: 15px 0px;
    font-size: 16px;
    border-bottom: solid 1px #000;
}

.google-maps-search-box input{
    width: 100%;
    padding: 5px 35px;
    margin-bottom: 5px;
    border: none;
}

.google-maps-search-box svg{
    position: absolute;
    top: calc(50% - 10px);
    left: 7.5px;
}

.google-maps-places-search__warapper {
    position: relative;
}

.google-maps-places-search__warapper .google-maps-search-box__warapper {
    position: absolute;
    top: 0px;
    width: 100%;
    border: none;
}

.google-maps-places-search__warapper .google-maps-search-box__warapper .google-maps-search-box {
    position: relative;
    display: flex;
    justify-content: center;
    border: none;
    color: #000;
}

.google-maps-places-search__warapper .google-maps-search-box__warapper .google-maps-search-box input {
    width: auto;
    background: transparent;
    color: #fff;
    font-weight: bold;
    border-bottom: solid 1px #000;
}

.google-maps-places-search__warapper .google-maps-search-box__warapper .google-maps-search-box svg {
    left: calc(50% - 123px);
}