.new-expense-form{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    height: inherit;
    padding: 20px;    

    &__action-form{
        width: 100%;

        &-custom-form-group{
            position: relative;
        }
    }
    &__action-btn{
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }
}

.custom-input{
    height: 50px;
    width: 100%;
    border-radius: 17px;
    border: none;
    padding: 0 17px;
    position: relative;
    color: #313C52 !important;
    font-size: 18px !important;
    font-weight: 500;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    &::-webkit-input-placeholder { /* Edge */
        color: #64707A;
      }
      
      &::-ms-input-placeholder { /* Internet Explorer 10-11 */
        color: #64707A;
      }
      
      &:placeholder {
        color: #64707A;
      }
}

.slim-input{
    height: 40px;
}

.custom-label{
    color: #64707A;
    font-size: 16px;
    width: inherit;
    padding: 0 17px;
}

.btn-detail {
    padding: 18px;
    font-size: 15px;
    color: white;
    border-radius: 17px;
    min-width: 172px;
    border: 47px;
    box-shadow: 0 3px 6px #00000029;
    text-transform: uppercase;
    background-color: rgb(35, 161, 219);
    width: inherit;
}