.new-expense-form{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    height: inherit;
    padding: 20px;    

    &__action-form{
        width: 100%;

        &-custom-form-group{
            position: relative;
        }
    }
    &__action-btn{
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
}

.custom-input{
    height: 50px;
    width: 100%;
    border-radius: 17px;
    border: none;
    padding: 0 17px;
    position: relative;
    color: #313C52 !important;
    font-size: 18px !important;
    font-weight: 500;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    &::-webkit-input-placeholder { /* Edge */
        color: #64707A;
      }
      
      &::-ms-input-placeholder { /* Internet Explorer 10-11 */
        color: #64707A;
      }
      
      &:placeholder {
        color: #64707A;
      }
}

.slim-input{
    height: 40px;
}

.custom-label{
    color: #64707A;
    font-size: 16px;
    width: inherit;
    padding: 0 17px;
}

.add-new-expense-form{
    height: 75px;
    width: 100%;
    display: flex;
    position: absolute;
    bottom: 0;
    left: 0;

    &__pay-action-btn {
        height: inherit;
        width: inherit;
        border: 0;
        background-color: #8051A1;
        color: white;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    &__save-action-btn {
        height: inherit;
        width: inherit;
        border: 0;
        background-color: #23A1DB;
        color: white;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
}

.expansion-panel-expende {
    box-shadow: none !important;
    background-color: #efeff4 !important;

    &:before {
        background-color: transparent !important;
    }
}