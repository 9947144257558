.finance-container {
    font-family: 'Roboto',
}
.loading{
    filter: opacity(0.5);
}

.bgGreyScale{
    filter: grayscale(1);
}

.appbar{
    background-color: #f6f6f7 !important;
    box-shadow: 0px 2px 4px -1px rgba(0,0,0,0.2) !important;
}

.list-content{
    margin-top: 48px !important;
}
.section-title{
    color: #313C52;
    font-size: 18px;
    font-weight: 600;
}
.list-item-bg{
    background-color: #fff !important;
}
.backdrop-transparent{
    background-color: transparent !important;
}
.dialog-bg{
    background-color: #EFEFF4 !important;
    box-shadow: none !important;
}
.badge-color{
    background-color: #B6BEC6 !important;
    color: white;
    width: 22px;
    height: 22px;
}
.down-zIndex{
    /* z-index: 1050 !important; */
    z-index: 1111 !important;
}
