.get-expense-form{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    height: inherit;
    padding: 20px;    

    &__action-form{
        width: 100%;

        &-custom-form-group{
            position: relative;
        }
    }
    &__action-btn{
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
}

.custom-input{
    height: 50px;
    width: 100%;
    border-radius: 17px;
    border: none;
    padding: 0 10px;
    position: relative;
    color: #313C52 !important;
    font-size: 18px !important;
    font-weight: 500;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    &::-webkit-input-placeholder { /* Edge */
        color: #64707A;
      }
      
      &::-ms-input-placeholder { /* Internet Explorer 10-11 */
        color: #64707A;
      }
      
      &:placeholder {
        color: #64707A;
      }
}

.slim-input{
    height: 40px;
}

.custom-label{
    color: #64707A;
    font-size: 16px;
    width: inherit;
    padding: 0 17px;
}

.done {
    background-color: #ffffffdb !important;
}
.dialog-container{
    position: absolute;
    width: 200px;
    height: 200px;
    top: 50%;
    left: 50%;
    margin-left: -100px;
    margin-top: -100px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.done-fab {
    width: 100px !important;
    height: 100px !important;
    
}
.done-title {
    font-size: 30px;
    color: #23a1db;
}

.partida {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: white;
}

.fz-15 {
    font-size: 15px;
}

.fz-12 {
    font-size: 12px;
}

.icon-arrows {
    height: 50px;
    display: flex;
    align-items: center;
    background-color: #efeff4;
    padding: 0 15px;
}

.btn-detail {
    position: initial !important;
    max-width: 100% !important;
}