.pos {
  &_menu {
    &_setDir {
      & > span, button {
        flex-direction: column !important;
      }
    }
  }
  &_sec {
    &_cart {
      height: 99%;
      display: flex;
      min-height: 30vh;
      border-radius: 0;
      flex-direction: column;
      box-shadow: $comercia-basic-shadow;
      background-color: #ffffff !important;
      transition: height 400ms cubic-bezier(0.075, 0.82, 0.165, 1);

      &_collapsed {
        transition: height 400ms cubic-bezier(0.075, 0.82, 0.165, 1);
        height: 40% !important;
        display: flex;
        min-height: 30vh;
        border-radius: 0.6em;
        flex-direction: column;
        box-shadow: $comercia_basic_shadow;
      }
    }
  }

  &__tab-container {
    position: fixed;
    width: 100%;
    z-index: 9;
    background-color: #efefef;
  }

  &__tab {
    width: 50%;
    padding: 10px 0px;
    cursor: pointer;
    background-color: transparent;
    border: none;
  }

  &__tab-active {
    background-color: #e1e1e1;
  }

  &__fix-position {
    padding-top: 40px;
  }

  .nav-item {
    width: 50%;
    text-align: center;
  }
}

.react-swipe {
  overflow: hidden;
  visibility: hidden;
  position: relative;
}

.react-swipe-wrap {
  overflow: hidden;
  position: relative;
}

.react-swipe-wrap>div {
  float: left;
  width: 100%;
  position: relative;
}

.message__wrapper {
  position: absolute;
  top: 50%;
  left: calc(50% - 90px);
}

.message__text {
  font-size: 30px;
  font-weight: bold;
}

.customizer__btn {
  display: none;
}

.category {
  .nav-item {
    display: none !important;
  }

  .ml-auto {
    margin-left: 0px !important;
  }
}

.bottom-tabs {
  .active {
    border-bottom: solid 5px #3f51b5;
  }
}

/* Hot FIX */
.navHeight {
  button:focus {
    outline: none !important;
  }

  button:active {
    outline: none !important;
  }
}

.posMobile .grid-search-filters {
  min-width: none !important;
  flex-direction: column !important;
}

.posMobile .nav {
  margin-top: 15px;
  margin-bottom: 25px;
}

.posMobile .img_section {
  width: 100% !important;
  padding: 0 0 !important;
  margin: 0 !important;
  display: flex;
  justify-content: center;
  align-items: center;

  &>img {
    width: 125px;
  }
}

.posMobile div.tab-pane:nth-child(2) {
  max-width: 85vw !important;
}

.posMobile {
  margin-bottom: 4.2em;
}

.main_actions_container {
  height: 48px;
  background-color: #fff;

  &>button>span>span>svg {
    fill: #646f79;
  }

  &>button>span>span>span {
    background-color: #23a1db;
  }

  .orderNoMobile {
    font-size: 1.2em;
  }

  &>div>div>div>button>span {
    font-size: 1.4em;
  }

  &>svg {
    font-size: 1.4em;
  }
}

.pos__center-container {
  display: flex;
  align-content: center;
  justify-content: center;
  align-items: center;
  justify-items: center;
  width: 100%;
  height: 100%;
}

.pos__tab-contaniner button {
  min-width: 25px !important;
}

.pos__tab-contaniner.pos__tab-type button span span {
  font-weight: bold;
  font-size: 12px;
}

.pos__tab-contaniner.pos__tab-display button {
  margin: 0px 5px;
  padding: 5px;
  min-height: 35px;
  border-radius: 10px;
}

.pos__tab-contaniner.pos__tab-display button[aria-selected="true"] {
  background-color: $comercia-blue;
}

.pos__tab-contaniner.pos__tab-display button[aria-selected="true"] svg {
  color: #fff;
}

.pos__tab-contaniner.pos__tab-display span:last-child {
  display: none !important;
}

.grid__container,
.table__container {
  padding: 10px;
  margin: 0px;
  height: 100%;
  height: calc(100vh - 10rem);
  margin-top: 5px;
  overflow: auto;

  @media screen and (max-width: 425px) {
    & {
      margin-top: 0px !important;
      max-height: 100%;
    }
  }
}

.table__container {
  height: calc(100vh - 10rem);
}

@media (max-width: 600px) {
  .MainWrapper-drawerHeader-186 {
    padding: 3.2em 3px !important;
  }
}

@media (max-width: 600px) {
  body {
    background-color: #fff !important;
  }

  .MuiPaper-elevation4-36 {
    box-shadow: none !important;
  }

  .box_shadow_none {
    box-shadow: none !important;
  }

  .MainWrapper-drawerHeader-186 {
    padding: 0 0.1em !important;
    min-height: 0px !important;
  }

  .MainWrapper-content-187 {
    padding: 0.05em 0 !important;
  }

  .MuiAppBar-positionFixed-22 {
    position: relative !important;
  }

  .positionRelative {
    position: relative !important;
  }

  .Product-paperRoot-210 {
    margin-top: 0.05em !important;
  }

  .product-grid {
    min-height: 75vh !important;
  }

  .posMobile>div {
    border-radius: 0px;
  }

  .Order-paperRoot-345 {
    margin-top: 0.05em !important;
  }

  .bottom-tabs {
    color: #b7bfc6;

    &>button>span>span {
      font-weight: 800 !important;
    }

    & .active {
      border-bottom: none !important;
      color: #313c52 !important;
    }
  }
}

.pos_type_tabs {
  &[data-general-tab="Vender"] {
    &>div>div>span {
      background-color: $comercia-blue !important;
    }
  }

  &[data-general-tab="Historico"] {
    &>div>div>span {
      background-color: $comercia-purple !important;
    }
  }

  &[data-general-tab="Cotizaciones"] {
    &>div>div>span {
      background-color: $color-gray !important;
    }
  }

  &>div>div>div>button {
    outline: none !important;

    &:focus {
      outline: none !important;
    }

    &[aria-selected="true"] {
      &[data-tab="0"] {
        &>span>svg {
          color: $comercia-blue !important;
        }

        &>span>span>span {
          color: $comercia-blue !important;
        }
      }

      &[data-tab="1"] {
        &>span>svg {
          color: $comercia-purple !important;
        }

        &>span>span>span {
          color: $comercia-purple !important;
        }
      }

      &[data-tab="2"] {
        &>span>svg {
          color: $color-gray !important;
        }

        &>span>span>span {
          color: $color-gray !important;
        }
      }
    }

    &[aria-selected="false"] {
      &>span>svg {
        opacity: 0.5;
      }

      &>span>span>span {
        opacity: 0.5;
      }
    }
  }
}

.chip-small {
  height: 24px !important;
  & > span {
    padding-left: 8px;
    padding-right: 8px;
  }
}

@media (max-height: 500px) {
  .MuiBottomNavigation-root-190 {
    height: 30px !important;
  }

  .navHeight {
    height: 30px !important;
  }
}

@media (max-height: 450px) {
  .bottom-tabs {
    height: 7vh !important;

    &>button>span>span {
      display: none;
    }
  }
}

html:not([data-scroll="0"]) {
  .main_actions_container {
    box-shadow: 0 0 0.5em rgba(0, 0, 0, 0.5);

    button:focus {
      outline: none;
    }

    button:active {
      outline: none;
    }
  }
}