.modal-container{
    min-width: 200px !important;
}

.modal-avatar{
    border-radius: 0 !important;
    background-color: #313C52 !important;
    color: #fff !important;
    height: 52px !important;
    width: 52px !important;
}

.modal-primary-text {
    font-size: 20px !important;
    color: #313C52 !important;
    font-weight: 600 !important;
}

.modal-secondary-text {
    font-size: 13px !important;
    color: #B6BEC6 !important;
    font-weight: 500 !important;
}

.modal-loader{
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px;
    position: absolute;
    z-index: 9999;
    width: 100%;
    height: 100%;
    background-color: #00000052;
}