.input {
  &_money {
    &_container {
      display: flex;
      width: 100% !important;
      & > span {
        position: absolute;
        right: 0;
        font-size: 14px;
        padding: 6px 40px;
        font-size: 16px;
        & > svg {
          transition: color 300ms ease-out !important;
        }
      }
    }
    display: block;
    font-size: 18px;
    padding: 10px 40px 10px 15px;
    border-radius: 18px;
    border: none;
    box-sizing: border-box;
    width: 100%;
  }
}
