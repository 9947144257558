.inputButton {
  color: #fff !important;
  border: none !important;
  padding: 14px !important;
  min-width: 200px !important;
  border-radius: 14px !important;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.3) !important;
  display: flex !important;
  text-transform: uppercase !important;
  letter-spacing: .5px !important;
  font-weight: 500 !important;
  font-size: 15px !important;
  transition: width 300ms linear !important;
  &:focus, &:active {
    outline: none !important;
  }
  &_loading {
    height: 24px; width: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    & > div > svg {
      color: #ffffff !important;
    }
  }
}
