.pos-container {
  position: relative;
}

.indexing-loader {
  position: fixed;
  background-color: #00000087;
  height: 100%;
  width: 100vw;
  top: 0;
  left: 0;
  z-index: 999;
  display: flex;
  justify-content: center;
  align-items: center;
}

.left-panel {
  float: left;
  /* width: 50%; */
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
  flex-direction: column;
  grid-gap: 15px;
  gap: 15px;
  position: absolute;
  z-index: 9;
  background-color: white;
  height: calc(100% - 0px);
  padding: 20px;
  min-width: 320px;
  border: 1px solid #e7e7e7;
  transition: all 0.5s ease-out;
  overflow-y: auto;
  }

  .left-panel-overlay {
    width: 100%;
    background-color: #242222b5;
    height: 100%;
    position: absolute;
    top: 0;
    z-index: 9;
    overflow: hidden;
    left: 0;
    
  }
  
  .right-panel {
    margin-left: 51%;
    height: calc(100vh - 80px);
    padding: 10px;
  }
  
  .ais-Hits {
    height: calc(100vh - 190px);
    overflow: auto;
    background-color: '#f3f3f3' !important;
  }

  .ais-Hits-item {
    padding: 0;
    border: 0;
    width: 150px;
  }

  .ais-Hits-list {
    display: flex;
    justify-content: center;
  }

  .ais-SearchBox-input {
    height: 40px;
  }

  .ais-SearchBox-input:focus {
    outline: none ;
  }

  /* Filters styles */
  .ais-RefinementList-label {
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
  }
  .ais-RefinementList-checkbox {
    margin-right: 5px;
  }

  .ais-RefinementList-labelText {
    font-size: 14px;
    flex: 1;
    text-align: left;
  }

  .ais-RefinementList-count {
    font-size: 14px;
    text-align: right;
    background-color: #8151a1;
    color: white;
  }

  .ais-CurrentRefinements-list {
    flex-wrap: nowrap;
  }

  .ais-CurrentRefinements {
    overflow-x: auto;
  }

  .ais-HierarchicalMenu-link {
    display: flex;
    align-items: center;
    margin-bottom: 8px;
  }

  .ais-HierarchicalMenu-label {
    width: 100%;
    color: #454f78;
  }

  .ais-HierarchicalMenu-count {
    background-color: #8151a1;
    color: white;
  }

  .slider .thumb {
    top: -5px;
}

.example-track {
  background-color: #8151a1;
}

.ais-CurrentRefinements-item {
  background-color: #24a2db !important;
}

.ais-ToggleRefinement-checkbox {
  margin-right: 5px;
}

li.ais-CurrentRefinements-item:hover {
  background-color: #24a2db !important;
  cursor: pointer;
}

.ais-SortBy-select {
  appearance: none;
    padding: 0.3rem 1.7rem;
    width: 100%;
    position: relative;
    background-color: #fff;
    border: 1px solid #c4c8d8;
    border-radius: 5px;
    text-align: start;
}

.ais-ToggleRefinement-label {
  margin-bottom: 0;
}


