//Place Holders

%size-50 {
  height: $size-50 !important;
  width: $size-50 !important;
  line-height: $size-50;
}

%size-60 {
  height: $size-60 !important;
  width: $size-60 !important;
  line-height: $size-60;
}

%size-70 {
  height: $size-70 !important;
  width: $size-70 !important;
  line-height: $size-70;
}

%size-80 {
  height: $size-80 !important;
  width: $size-80 !important;
  line-height: $size-80;
}

%size-90 {
  height: $size-90 !important;
  width: $size-90 !important;
  line-height: $size-90;
}

%size-100 {
  height: $size-100 !important;
  width: $size-100 !important;
  line-height: $size-100;
}

%size-36 {
  height: ($size-30 + 6) !important;
  width: ($size-30 + 6) !important;
  line-height: ($size-30 + 6);
}

%size-30 {
  height: $size-30 !important;
  width: $size-30 !important;
  line-height: $size-30;
}

%size-20 {
  height: $size-20 !important;
  width: $size-20 !important;
  line-height: $size-20;
}

%size-10 {
  height: $size-10 !important;
  width: $size-10 !important;
  line-height: $size-10;
}

%size-8 {
  height: ($size-10 - 2) !important;
  width: ($size-10 - 2) !important;
  line-height: ($size-10 - 2);
}

%size-40 {
  height: $size-40 !important;
  width: $size-40 !important;
  line-height: $size-40;
}

%size-120 {
  height: $size-120 !important;
  width: $size-120 !important;
  line-height: $size-120;
}