.drop-up-container {
    position: relative;
}

.drop-up-selected-container{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    background-color: #8252a1;
    height: 46px;
    color: white;
    border-radius: 6px;
}

.drop-up-select {
    flex: 1;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 15px;
}

.drop-up-toggle, .drop-up-select {
    padding: 12px 10px;
    cursor: pointer;
}

.option-title {
    font-size: 19px;
}

.drop-up-toggle {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 19px;
    width: 40px;
}

.drop-up-toggle-1 {
    background-color: transparent;
    position: absolute;
    bottom: 0;
    right: 0;
    z-index: 99999;
    height: 45px;
    border-radius: 5px;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 19px;
    width: 40px;
    border-left: 1px solid;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}

.border-left {
    border-right: 1px solid;
}

.drop-up-options {
    position: absolute;
    // top: -93px;
    bottom: 0;
    width: 100%;
    background-color: #8252a2;
    border-radius: 6px;
}