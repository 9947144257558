.alert-box {
    position: fixed;
    top: 20%;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 9999;
    width: 100%;
    height: 100%;
}

.alert-box__container {
    box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
    transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
    -webkit-border-radius: 2px;
    -moz-border-radius: 2px;
    border-radius: 2px;
    background: #fff;
    padding: 25px 25px 15px;
    text-align: center;

    float: none;
    margin-left: auto;
    margin-right: auto;

    max-width: 500px;
}

.alert-box__icon {
    -webkit-animation: fall-in 0.75s;
    -moz-animation: fall-in 0.75s;
    -o-animation: fall-in 0.75s;
    animation: fall-in 0.75s;
    box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);

    position: relative;
    margin: 0 auto;
    margin-top: -75px;
    height: 100px;
    width: 100px;
    border-radius: 50%;

    svg {
        font-size: 5em;
        color: #fff;
        text-align: center;
        margin-top: 18px;
    }
}

.alert-box__text {
    h1 {
        font-family: 'Montserrat', sans-serif;
    }

    p {
        font-family: 'Open Sans', sans-serif;
    }
}

.alert-box__btn {
    &, &:focus, &:active {
        -webkit-transition: all 0.1s ease-in-out;
        transition: all 0.1s ease-in-out;
        -webkit-border-radius: 30px;
        -moz-border-radius: 30px;
        border-radius: 30px;
        margin-top: 15px;
        width: 80%;
        background: transparent;
        color: #4caf50;
        border-color: #4caf50;
        outline: none;
    }

    &:hover {
        color: #fff;
        background: #4caf50;
        border-color: transparent;
    }
}

.alert-box__information {
    .alert-box__icon {
        background: $color-information;
    }

    .alert-box__btn {
        & {
            background: transparent;
            color: $color-information !important;
            border-color: $color-information !important;
        }
    
        &:hover, &:before, &:focus, &:active {
            color: #fff !important;
            background: $color-information !important;
            border-color: transparent;
        }
    }
}

.alert-box__success {
    .alert-box__icon {
        background: $color-success;
    }

    .alert-box__btn {
        & {
            background: transparent;
            color: $color-success !important;
            border-color: $color-success !important;
        }
    
        &:hover, &:before, &:focus, &:active {
            color: #fff !important;
            background: $color-success !important;
            border-color: transparent;
        }
    }
}

.alert-box__warning {
    .alert-box__icon {
        background: $color-warning;
    }

    .alert-box__btn {
        & {
            background: transparent;
            color: $color-warning !important;
            border-color: $color-warning !important;
        }
    
        &:hover, &:before, &:focus, &:active {
            color: #fff !important;
            background: $color-warning !important;
            border-color: transparent;
        }
    }
}

.alert-box__danger {
    .alert-box__icon {
        background: $color-danger;
    }

    .alert-box__btn {
        & {
            background: transparent;
            color: $color-danger !important;
            border-color: $color-danger !important;
        }
    
        &:hover, &:before, &:focus, &:active {
            color: #fff !important;
            background: $color-danger !important;
            border-color: transparent;
        }
    }
}

@-webkit-keyframes fall-in {
  0% {
    -ms-transform: scale(3, 3);
    -webkit-transform: scale(3, 3);
    transform: scale(3, 3);
    opacity: 0;
  }
  50% {
    -ms-transform: scale(1, 1);
    -webkit-transform: scale(1, 1);
    transform: scale(1, 1);
    opacity: 1;
  }
  60% {
    -ms-transform: scale(1.1, 1.1);
    -webkit-transform: scale(1.1, 1.1);
    transform: scale(1.1, 1.1);
  }
  100% {
    -ms-transform: scale(1, 1);
    -webkit-transform: scale(1, 1);
    transform: scale(1, 1);
  }
}
@-moz-keyframes fall-in {
  0% {
    -ms-transform: scale(3, 3);
    -webkit-transform: scale(3, 3);
    transform: scale(3, 3);
    opacity: 0;
  }
  50% {
    -ms-transform: scale(1, 1);
    -webkit-transform: scale(1, 1);
    transform: scale(1, 1);
    opacity: 1;
  }
  60% {
    -ms-transform: scale(1.1, 1.1);
    -webkit-transform: scale(1.1, 1.1);
    transform: scale(1.1, 1.1);
  }
  100% {
    -ms-transform: scale(1, 1);
    -webkit-transform: scale(1, 1);
    transform: scale(1, 1);
  }
}
@-o-keyframes fall-in {
  0% {
    -ms-transform: scale(3, 3);
    -webkit-transform: scale(3, 3);
    transform: scale(3, 3);
    opacity: 0;
  }
  50% {
    -ms-transform: scale(1, 1);
    -webkit-transform: scale(1, 1);
    transform: scale(1, 1);
    opacity: 1;
  }
  60% {
    -ms-transform: scale(1.1, 1.1);
    -webkit-transform: scale(1.1, 1.1);
    transform: scale(1.1, 1.1);
  }
  100% {
    -ms-transform: scale(1, 1);
    -webkit-transform: scale(1, 1);
    transform: scale(1, 1);
  }
}
