.icon-border{
    border: 1px solid #64707A;
    padding: 10px;
    border-radius: 50%;
    height: 40px;
    width: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.activities_secondary-title{
    color: #B6BEC6;
}
.activities_primary-title{
    font-size: 18px !important;
    font-weight: normal;
    color: #313C52 !important;
}