// Progres bar
.progresBar {
    position: relative;
    height: 20px;
    width: 100%;
    border-radius: 50px;
    background-color: #efeff4;
  }
  .filter {
    height: 100%;
    background-color: #313c52;
    border-radius: inherit;
    -webkit-transition: width .2s ease-in;
    transition: width .2s ease-in;
    border-radius: 20px;
    min-width: 12%;
    
}
.danger {
    background-color: red;
    width: 100%;
}

.amount {
    color: white;
    display: flex;
    justify-content: flex-end;
    padding: 0 5px;
    font-weight: normal;
    font-size: 13px;
    &__dark {
        color: #313c52;
        filter: opacity(0.7) !important;
        margin-left: 5px;
    }
}

.progresBarHeader {
    display: flex;
    justify-content: space-between;
    padding: 5px;
    font-size: 16px;
    font-weight: bold;
}
.progresBarFooter {
    display: flex;
    justify-content: flex-end;
    padding: 5px;
    font-size: 14px;
    font-weight: bold;
}
.progresBarDone {
    filter: opacity(0.7) !important;
}
.progresBarTransparent {
    background-color: transparent !important;
}
