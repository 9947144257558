.poliza-container{
    padding: 0 1.5rem;
}
.poliza-header{
    color: #313C52;
    font-size: 20px;
    font-weight: 600;
}

.poliza-description{
    display: flex;
    justify-content: center;
    align-items: center;
    margin: .5rem 0;

    .poliza-description_item{
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;

        h4{
            color: #313C52;
            font-size: 20px;
            font-weight: 600;
        }

        span{
            color: #B6BEC6;
            font-size: 13px;
            font-weight: 500;
        }
    }
}
