.appBar {
    position: 'relative',
}
.dialog-bg{
    background-color: #EFEFF4;
}

.toolbar-title {
    margin-left: 40px !important;
}

.title-type{
    color: #64707A !important;
    font-size: 18px !important;
    font-weight: 500 !important;
}

.btn-detail {
    position: absolute !important;
    bottom: 10px !important;
    max-width: 90% !important;
}

