.document-item{
    background-color: #fff;
}
.document-title{
    color: #313C52 !important;
    font-size: 20px !important;
    font-weight: 500 !important;
}
.document-subtitle{
    color: #B6BEC6 !important;
    font-size: 13px !important;
    font-weight: 500 !important;
}