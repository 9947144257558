.text_field {
  &:hover {
    & > div > fieldset {
      border: 1.2em;
    }
  }
  &_vender {
    width: 100% !important;
    & > div > fieldset {
      border-color: $comercia-blue !important;
    }
    & > div {
      height: 3em !important;
    }
    & > div > div {
      color: $comercia-blue !important;
    }
    & > div > div > svg {
      fill: $comercia-blue !important;
    }
    & > div > div > div {
      text-align: center !important;
    }
  }
  &_historico {
    width: 100% !important;
    & > div > fieldset {
      border-color: $comercia-purple !important;
    }
    & > div {
      height: 3em !important;
    }
    & > div > div {
      color: $comercia-purple !important;
    }
    & > div > div > svg {
      fill: $comercia-purple !important;
    }
    & > div > div > div {
      text-align: center !important;
    }
  }
}
