.email-list-container{
    background-color: #fff;
}
.email-title{
    color: #313C52 !important;
    font-size: 18px !important;
    font-weight: 500 !important;
}
.email-subtitle{
    color: #B6BEC6 !important;
    font-size: 13px !important;
    font-weight: 500 !important;
}

.empty-email{
    height: 60vh;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}