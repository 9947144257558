.warehouse-container {
  &__footer {
    position: fixed;
    bottom: 0;
    width: 520px !important;
  }
}

.react-datepicker__input-container input {
  min-height: 30px;
  border-color: transparent;
  outline: none;
  background-color: transparent;
}