$customer_box_s: 0px 1px 5px 0px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14),
  0px 3px 1px -2px rgba(0, 0, 0, 0.12);

@mixin customer_each_var($color, $opacity: 0.1) {
  border-radius: 0.4em !important;
  border: solid 0.1em $color;
  & > li > div > button > span > svg {
    fill: $color;
  }
  & > li > div { @include customer_trans(0.2s); }
  & > li > div:hover , div:focus{
    @include customer_trans(0.2s);
    background-color: rgba($color, $opacity)
  }
};

@mixin customer_trans($seconds) {
  -webkit-transition: background-color $seconds linear;
  -ms-transition: background-color $seconds linear;
  transition: background-color $seconds linear;
}

.customer {
  width: 100% !important;
  @include customer_trans(0.5s);
  &_caja {
    @include customer_trans(0.5s);
    @include customer_each_var($comercia-blue); 
  }
  &_ventas {
    @include customer_trans(0.5s);
    @include customer_each_var($comercia-purple);
  }
  &_quote {
    @include customer_trans(0.5s);
    @include customer_each_var($color-additional);
  }
  &_number{
    font: inherit;
    color: currentColor;
    width: 100%;
    border: 0;
    margin: 0;
    padding: 6px 0 7px;
    display: block;
    min-width: 0;
    box-sizing: content-box;
    background: none;
    -webkit-tap-highlight-color: transparent;
    overflow: visible;
    color: rgba(0, 0, 0, 0.87);
    cursor: text;
    display: inline-flex;
    font-size: 1rem;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    line-height: 1.1875em;
    align-items: center;
    &::before {
      left: 0;
      right: 0;
      bottom: 0;
      content: "\00a0";
      position: absolute;
      transition: border-bottom-color 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
      border-bottom: 1px solid rgba(0, 0, 0, 0.42);
      pointer-events: none;
    }
  }
}

.customer__is-default span {
  color: rgba(0, 0, 0, 0.54);
}

.customer__list {
  padding: 0px !important;
}

.customer__list-item {
  padding: 1.1em 25px !important;
}

@media (max-width: 600px) {
  .customer{
    background-color: #ffffff !important;
  }
}

.st0 {
  fill: #313C52;
}
.st1 {
  fill: #23A1DB;
}
.st2 {
  fill: #FFFFFF;
}