.react-swipeable-view-container {
    height: 100%;
}

.tab {
    height: 100%;
}
.h-90 {
    height: 88%;
    overflow: hidden !important;
}