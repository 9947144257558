.product-grid {
    height: calc(100vh - 190px);
    overflow: auto;
    background-color: #f6f6f7;

    .ais-Hits-item {
        padding: 0;
        gap: 1.5rem;
        box-shadow: none;
        min-width: 150px;
        border-radius: 8px;
        overflow: hidden;
    }
    .ais-Hits-list {
        margin: 0;
    }
}